import { Box } from '@components/v2/atoms';
import { Typography } from '@mui/material';
import Image from 'mui-image';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.appInsights = props.appInsights;
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.appInsights.trackException(error);
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ 
          height: '100vh', 
          width: '100vw', 
          background: '#003F43', 
          color: '#fff', 
          textAlign: 'center', 
          paddingTop: 'env(safe-area-inset-top,0)', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center'
        }}>
          <Image 
            duration={0} 
            width='195px' 
            height='76px'
            src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_White%20and%20grad.png'/>
          <Box sx={{ py: 1, paddingTop: 3 }}>
            <Typography variant='h4'>
            Something went wrong.
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
            Please restart the app and try again.
            </Typography>
          </Box>
          
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;