import React from 'react';

import { 
  Box,
  Typography,
  Stack
} from '@mui/material';

import UserAvatar from './user-avatar';
import { LoadableText, ProfileDetail } from '../atoms';
import useDialogsStore from '@stores/dialogs';

export default function UserDetails(props){
  const { user, isLoading } = props;
  const { setProfileDialogId } = useDialogsStore();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <UserAvatar size={32}
          user={user}
          isLoading={isLoading}
          useNameBasedColor={true} 
          onClick={() => setProfileDialogId(user?.id)}/>
      </Box>
      <Box sx={{ paddingLeft: 1.5 }} onClick={() => setProfileDialogId(user?.id)}>
        
        <Typography component='div'
          color='#000'
          fontWeight='medium'
        >
          <LoadableText isLoading={isLoading} text={user?.name} minWidth={50}/>
        </Typography>
        {(isLoading || user?.injuryType || user?.activityType) &&
          <Stack direction='row' spacing={1}>
            {(isLoading || user?.injuryType) &&
              <ProfileDetail icon='heart-beat' text={user?.injuryType} isLoading={isLoading}/>
            }
            {(isLoading || user?.activityType) &&
              <ProfileDetail icon='running-person' text={user?.activityType} isLoading={isLoading}/>
            }
          
          </Stack>
        }
      </Box>
    </Box>
  );
}