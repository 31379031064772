import React from 'react';

import { 
  Avatar,
  Box,
  Skeleton
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Icon } from '../atoms';

import config from '@config';

function stringToHslColor(str) {
  if(!str){
    return 'hsl(0, 0%, 50%)';
  }

  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+ 60 +'%, '+ 60 +'%)';
}

export default function UserAvatar(props){
  const { size = 40, user, profile, onClick, dataTestId, isLoading, useNameBasedColor = false, isEdit=false } = props;
  const theme = useTheme();

  var id = user?.id ?
    user.id :
    profile?.id;

  var doesUserHaveImage = true;

  if(user?.hasImage == false){
    doesUserHaveImage = false;
  }

  var src = id && doesUserHaveImage ? `${config.profileImagesBaseUrl}images/user-profile-image-${id}` : '';

  if(user && doesUserHaveImage){
    src = `${src}?lastmod=${user.userImageModified}`;
  }

  const isPro = user?.isAccountOnboardingComplete || profile?.isAccountOnboardingComplete;
  var proIconSize = size / 2.5;

  if(proIconSize > 25){
    proIconSize = 25;
  }

  if(isLoading){
    return(
      <Skeleton variant="circular" width={size} height={size} />
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Avatar 
        data-testid={dataTestId}
        onClick={onClick}
        src={src}
        sx={{ 
          width: size,
          height: size,
          backgroundColor: useNameBasedColor ? 
            stringToHslColor(user?.name) :
            theme.palette.braceGreenLight.main
        }} 
      >
        <Icon 
          size={size * 0.65}
          type='user'
          fill={theme.palette.braceGreenDark.main}
          stroke={'none'}/>
      </Avatar>
      {(!isEdit && isPro) &&
        <Box sx={{ position: 'absolute', bottom: -(proIconSize / 4), right: -(proIconSize / 4), lineHeight: 1 }}>
          <Box sx={{ 
            backgroundColor: theme.palette.braceGreenLight.main, 
            borderRadius: '50%',
            width: proIconSize,
            height: proIconSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Icon type='star' size={proIconSize * 0.66}/>
          </Box>
        
        </Box>
      }
      {(isEdit) &&
        <Box sx={{ position: 'absolute', bottom: -(proIconSize / 4), right: -(proIconSize / 4), lineHeight: 1 }}>
          <Box sx={{ 
            backgroundColor: theme.palette.primary.main, 
            borderRadius: '50%',
            width: proIconSize,
            height: proIconSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Icon 
              fill={theme.palette.secondary.main}
              type='camera' 
              size={proIconSize * 0.66}/>
          </Box>
        
        </Box>
      }

    </Box>
  );
}