
import React from 'react';

export default function InfinityIcon(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 8C4.42522 8 3 9.64616 3 12C3 14.3538 4.42522 16 6.5 16C6.89277 16 7.37581 15.8172 7.9365 15.4098C8.48745 15.0094 9.02767 14.4541 9.51373 13.8641C9.99579 13.279 10.4009 12.6889 10.6866 12.2423C10.7422 12.1554 10.7931 12.0742 10.8389 12C10.7931 11.9258 10.7422 11.8446 10.6866 11.7577C10.4009 11.3111 9.99579 10.721 9.51373 10.1359C9.02767 9.54588 8.48745 8.99058 7.9365 8.59022C7.37581 8.18279 6.89277 8 6.5 8ZM13.1611 12C13.2069 12.0742 13.2578 12.1554 13.3134 12.2423C13.5991 12.6889 14.0042 13.279 14.4863 13.8641C14.9723 14.4541 15.5125 15.0094 16.0635 15.4098C16.6242 15.8172 17.1072 16 17.5 16C19.5748 16 21 14.3538 21 12C21 9.64616 19.5748 8 17.5 8C17.1072 8 16.6242 8.18279 16.0635 8.59022C15.5125 8.99058 14.9723 9.54588 14.4863 10.1359C14.0042 10.721 13.5991 11.3111 13.3134 11.7577C13.2578 11.8446 13.2069 11.9258 13.1611 12ZM12 13.88C11.7349 14.2656 11.4167 14.6996 11.0573 15.1359C10.5136 15.7959 9.85138 16.4906 9.1122 17.0277C8.38275 17.5578 7.48794 18 6.5 18C3.05193 18 1 15.169 1 12C1 8.83099 3.05193 6 6.5 6C7.48794 6 8.38275 6.44221 9.1122 6.97228C9.85138 7.50942 10.5136 8.20412 11.0573 8.86414C11.4167 9.30035 11.7349 9.73442 12 10.12C12.2651 9.73442 12.5833 9.30035 12.9427 8.86414C13.4864 8.20412 14.1486 7.50942 14.8878 6.97228C15.6172 6.44221 16.5121 6 17.5 6C20.9481 6 23 8.83099 23 12C23 15.169 20.9481 18 17.5 18C16.5121 18 15.6172 17.5578 14.8878 17.0277C14.1486 16.4906 13.4864 15.7959 12.9427 15.1359C12.5833 14.6996 12.2651 14.2656 12 13.88Z"
        fill="currentColor" />
    </svg>
  );
}