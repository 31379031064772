import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Link,
  useLocation
} from 'react-router-dom';

import {
  useHistory
} from 'react-router';

import { useTheme } from '@mui/material/styles';

import {
  AppBar,
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import ForumIcon from '@mui/icons-material/Forum';
import useDialogsStore from '@stores/dialogs';

import { Avatar } from '@components/atoms';
import config from '@config';
import { UserContext } from '@context/user';
import { AccountRoles, hasRole } from '@helpers/roles';
import { grey } from '@mui/material/colors';

import Image from 'mui-image';

const drawerWidth = 240;

const MenuItem = (props) => {
  const location = useLocation();

  const { text, icon, to } = props;

  const isActive = location.pathname == to;

  return (
    <Link to={to}
      style={{ 
        textDecoration: 'none',
        color: 'inherit'
      }}>
      <ListItem disablePadding>
        <ListItemButton selected={isActive}>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} color='text.primary' />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

function ResponsiveDrawer(props) {
  const { window, children, enableBackButton } = props;
  const history = useHistory();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user, activeOrganisation, activeOrganisationAccount, handleLogout } = useContext(UserContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  var braceImageUrl = theme.palette.mode == 'dark' ? 
    'https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_RGB_White.png' :
    'https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_RGB_Black.png' ;

  const Drawer = (p) => {
    var { idPostFix } = p;
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Toolbar sx={{ top: 'env(safe-area-inset-top)', maxHeight: '100px', justifyContent: 'center' }}>
          <Box>
            <Image 
              duration={500} 
              height='60px' 
              src={braceImageUrl}/>
          </Box>

        </Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flexGrow: 1 }}>
            <MenuItem text='Clients' icon={<PersonIcon />} to='/clients'/>
            {hasRole(AccountRoles[activeOrganisationAccount?.role], AccountRoles.ADMIN) &&
            <MenuItem 
              text='Groups'
              icon={<GroupsIcon />}
              data-testid='menu-drawer-button-groups'
              to='/groups'
            />
            }
            <MenuItem text='Plans' icon={<FitnessCenterIcon />} to='/plans'/>
            <MenuItem 
              
              text='Community'
              icon={<ForumIcon data-testid={`menu-drawer-button-community-${idPostFix}`}/>}
              to='/community'/>
           
          </Box>
          <MenuItem text='Settings'
            data-testid='menu-drawer-button-settings'
            icon={<SettingsIcon />}
            to='/settings'
          />
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout(() => history.push('/index'))}  data-testid={`menu-drawer-button-log-out-${idPostFix}`} >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </ListItem>
        </Box>
      </Box>
    );
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <AppBar
        position="fixed"
        sx={{
          top: 'env(safe-area-inset-top)',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: theme.palette.background.default,
          color: 'text.primary',
          boxShadow: 'none',
          minHeight: '64px'
        }}
      >
        <Toolbar sx={{ minHeight: '64px' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h5'
              data-testid= 'menu-drawer-active-organisation-name'
              fontWeight='bold'
              component='div'
              sx={{
                display: { xs: 'none', sm: 'block' }
              }}>
              {activeOrganisation?.name}
            </Typography>
            {!enableBackButton &&
            <IconButton
              data-testid='menu-drawer-button-expand'
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
            >
              <MenuIcon />
              
            </IconButton>
            }
            {enableBackButton &&
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => history. goBack()}
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            }
          </Box>
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                
                <Box sx={{ marginRight: 1, textAlign: 'right' }}>
                  <Typography variant='body1'
                    fontWeight='medium'
                    data-testid= 'menu-drawer-account-name'
                    sx={{ textDecoration: 'none' }}
                    color='text.primary'
                    to={`?profileId=${user?.id}`}
                    component={Link}>
                    {user?.name}
                  </Typography>
                  <Typography variant='body2' data-testid= 'menu-drawer-active-organisation-role'>
                    {AccountRoles[activeOrganisationAccount?.role]}
                  </Typography>
                </Box>
                <Box sx={{ cursor: 'pointer' }}>
                  <Avatar
                    linkTo={`?profileId=${user?.id}`}
                    name={user?.name}
                    src={user?.imageLocation ? `${config.contentBaseUrl}images/${user.imageLocation}?lastmod=${user.userImageModified ?? '1'}` : null}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <MuiDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
        >
          <Drawer idPostFix='temporary'/>
        </MuiDrawer>
        <MuiDrawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
          open
        >
          <Drawer idPostFix='permanent'/>
        </MuiDrawer>
      </Box>
      <Box
        sx={{ 
          flexGrow: 1, 
          width: '100%',
          background: theme.palette.mode === 'dark' ? grey[900] : grey[100]  }}
      >
        <Toolbar sx={{ minHeight: '64px', top: 'env(safe-area-inset-top)' }}/>
        <Box  sx={{ 
          height: 'calc(100vh - env(safe-area-inset-top) - 64px - env(safe-area-inset-bottom))'
        }}>
          {children}
        </Box>
        
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default ResponsiveDrawer;