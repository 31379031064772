import React from 'react';

import { 
  Card as MuiCard
} from '@mui/material';

export default function Card(props){
  const { sx, shadow = true, ...muiProps } = props;

  return (
    <MuiCard 
      {...muiProps}
      sx={{ ...sx,
        borderRadius: sx?.borderRadius ?? 2,
        background: sx?.background ?? '#fff',
        boxShadow: shadow ? '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 23px 44px rgba(176, 183, 195, 0.12)' : 'none'
      }}
    />
  );
}