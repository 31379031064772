
import React from 'react';

export default function CalendarTrial(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={props.fill}
      {...props}>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2497 13.2251C17.5494 13.2251 17.8223 13.398 17.9503 13.669L19.0439 15.983L21.5633 16.1907C21.8659 16.2156 22.126 16.4149 22.2288 16.7006C22.3317 16.9862 22.2582 17.3056 22.041 17.5177L20.2188 19.2961L20.741 21.8445C20.8015 22.1397 20.6854 22.4432 20.4434 22.6226C20.2013 22.8021 19.8772 22.825 19.6124 22.6814L17.2496 21.4002L14.8869 22.6814C14.622 22.825 14.2979 22.8021 14.0559 22.6226C13.8139 22.4432 13.6978 22.1397 13.7583 21.8445L14.2804 19.2961L12.4583 17.5177C12.241 17.3056 12.1676 16.9862 12.2704 16.7006C12.3733 16.4149 12.6334 16.2156 12.936 16.1907L15.4554 15.983L16.549 13.669C16.677 13.398 16.9499 13.2251 17.2497 13.2251Z"
        fill={props.fill}
        stroke='none'  />
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C6.55228 1 7 1.44772 7 2V5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5V2C5 1.44772 5.44772 1 6 1ZM18 1C18.5523 1 19 1.44772 19 2V5C19 5.55228 18.5523 6 18 6C17.4477 6 17 5.55228 17 5V2C17 1.44772 17.4477 1 18 1Z"
        fill={props.fill} 
        stroke='none' />
      <path d="M1.5 6C1.5 4.34315 2.84315 3 4.5 3H19.5C21.1569 3 22.5 4.34315 22.5 6V12.5C22.5 13.0523 22.0523 13.5 21.5 13.5C20.9477 13.5 20.5 13.0523 20.5 12.5V9.5H3.5V20C3.5 20.5523 3.94772 21 4.5 21H10.5C11.0523 21 11.5 21.4477 11.5 22C11.5 22.5523 11.0523 23 10.5 23H4.5C2.84315 23 1.5 21.6569 1.5 20V6Z" 
        fill={props.fill}
        stroke='none' />
    </svg>
  );
}