import React from 'react';

import { 
  Slide,
  Dialog as MuiDialog,
  Box,
  DialogTitle
} from '@mui/material';
import { Close } from '@material-ui/icons';

import { IconButton, Divider } from './';
import { useTheme } from '@emotion/react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function Dialog(props){
  var { 
    children, 
    isOpen = false, 
    close, 
    direction = 'up', 
    variant='fixed-bottom',
    enableCloseButton=true,
    closeButtonIcon = <Close />,
    isDenseHeader = false,
    title,
    theme = 'light',
    button
  } = props;
  const muiTheme = useTheme();

  return (
    <MuiDialog 
      TransitionComponent={Transition}
      TransitionProps={{ direction }}
      onClose={close}
      fullScreen={variant == 'fullscreen'}
      sx={{ 
        '.MuiDialog-container > .MuiPaper-root': {
          position: 'fixed', 
          bottom: 'env(keyboard-inset-height, 0px)', 
          backgroundColor: theme == 'light' ? muiTheme.palette.background.main : muiTheme.palette.primary.main,
          width: '100%',
          height: variant == 'fullscreen' ?
            '100%':
            variant == 'near-fullscreen' ? 
              '90vh' : 
              'auto',
          marginTop: 0,
          marginBottom: 0,
          paddingTop: variant == 'fullscreen' ? 'env(safe-area-inset-top)' : 0,
          paddingBottom: 'calc(8px + env(safe-area-inset-bottom))',
          borderRadius: '16px 16px 0px 0px'
        } }}
      open={isOpen}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {(enableCloseButton || title || button) &&
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {enableCloseButton && 
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              margin:isDenseHeader ? 0 : 2, 
              marginRight: 1,
              color: theme == 'light' ? 
                muiTheme.palette.grey[500] :
                muiTheme.palette.grey[200]
            }}
          >
            {closeButtonIcon}
          </IconButton>
          }
          <DialogTitle sx={{ flexGrow: 1, fontSize: 'h5.fontSize',
            px: 2, 
            py: 2,
            paddingLeft: enableCloseButton ? 0 : 2
          }}>{title}</DialogTitle>

          {button && 
        <Box
          sx={{
            padding:2, 
            paddingLeft: 1
          }}
        >
          {button}
        </Box>
          }
        </Box>
        }
        {!isDenseHeader &&
          <Divider light={theme == 'light'}/>
        }
        <Box sx={{ flexGrow:1 }}>
          {children}
        </Box>
      </Box>
    </MuiDialog>
    
  );
}