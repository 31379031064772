import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import { Icon, LoadableText } from '../atoms';
import { useTheme } from '@emotion/react';
import { isNullOrEmptyOrBlack } from '@helpers/text';

export default function ProfileDetail(props){
  const { text, icon, isLoading, iconSize=14, fontSize=10 } = props;
  const theme = useTheme();

  if(isNullOrEmptyOrBlack(text)){
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon 
        fill={theme.palette.text.secondary}
        stroke={theme.palette.text.secondary}
        size={iconSize}
        type={icon}
        isLoading={isLoading} />
      <Typography component='div'
        color='text.secondary'
        textAlign='center'
        fontSize={fontSize}
        marginLeft={0.5}>
        <LoadableText isLoading={isLoading} text={text} minWidth={50}/>
      </Typography>
    </Box>
  );
}