import React from 'react';

import {
  Tab as MuiTab,
  Tabs as MuiTabs
} from '@mui/material';

import { 
  Box
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';

export function a11yProps(index) {
  return {
    id: `brace-tab-${index}`,
    'aria-controls': `brace-tabpanel-${index}`
  };
}

export function Tab(props){
  const { label, dataTestId, width = '50%', dark = true, size='small', ...restOfProps } = props;
  const theme = useTheme();
  return (
    <MuiTab 
      data-testid={dataTestId}
      label={label}
      sx={{
        width: width,
        py:size == 'small' ? 1: 2,
        opacity: 1,
        fontSize: size == 'small' ? '1rem': '1.125rem',
        fontWeight: 'regular',
        color:  dark ? grey[100] : grey[400],
        '&.Mui-selected':{
          fontWeight: 'medium',
          color: dark ? theme.palette.secondary.main : theme.palette.primary.main
        }
      }}
      {...restOfProps}/>
  );
}

export default function Tabs(props){
  const { children, value, onChange, dark=true } = props;
  const theme = useTheme();
  return (
    <Box sx={{ 
      backgroundColor: dark ? theme.palette.primary.main : '#fff',
      '& .MuiTabs-indicator': {
        width: `calc(${100 / children.length}% - 8px) !important`,
        mx: '4px',
        height: 4,
        backgroundColor: theme.palette.secondary.main
      }
    }}>
      <MuiTabs value={value} onChange={onChange}>
        {children}
      </MuiTabs>
    </Box>
  );
}