
import React from 'react';

export default function Flame(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      color={props.fill}
      fill={'none'}
      {...props}>
      <path 
        d="M10.646 1.30307C10.8636 1.21656 11.1091 1.23663 11.3097 1.35734C15.5756 3.92373 19.5162 8.30625 20.807 12.5308C21.4578 14.6607 21.4555 16.8298 20.371 18.6902C19.2874 20.5493 17.2111 21.9488 14.0352 22.7284C13.7169 22.8065 13.3847 22.6689 13.2149 22.3885C13.0451 22.1082 13.077 21.75 13.2938 21.5041C14.5927 20.0302 15.7405 18.3078 15.9078 16.4936C16.0352 15.1126 15.6022 13.5477 13.9395 11.8829C13.9516 13.6546 13.2692 15.13 12.5818 15.9737C12.3984 16.1988 12.1016 16.2978 11.8198 16.2279C11.538 16.158 11.3219 15.9318 11.2649 15.6471C10.9901 14.2731 10.2095 13.6679 9.3781 13.2418C9.32173 14.4125 8.81109 15.2552 8.43945 15.8684C8.33448 16.0417 8.2406 16.1966 8.17118 16.3354C7.06544 18.5469 8.40425 20.1519 9.33056 21.2624L9.33057 21.2624C9.40669 21.3537 9.48002 21.4416 9.54898 21.5263C9.76275 21.7886 9.77434 22.1616 9.57727 22.4367C9.3802 22.7119 9.0233 22.8209 8.70611 22.703C5.84221 21.6379 3.81665 19.6428 3.06665 17.2085C2.31202 14.7593 2.89055 12 4.97052 9.51824C5.63777 8.72212 6.22077 8.06174 6.73411 7.48027C8.53991 5.43481 9.48374 4.36573 10.2007 1.79827C10.2637 1.57275 10.4284 1.38958 10.646 1.30307Z"
        fill="currentColor" />
    </svg>
  );
}