import React, { useState } from 'react';

import {
  Button,
  Skeleton
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { Icon, Box } from '@components/v2/atoms';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';

const CounterButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  minWidth: 32,
  '.MuiButton-startIcon': {
    marginRight: theme.spacing(0.5)
    
  },
  '.MuiButton-startIcon > :nth-of-type(1)': {
    fontSize: '1rem'
  }
}));

export default function LikeAndCommentButtons(props) {
  const theme = useTheme();
  const [likeCount, setLikeCount] = useState(0);
  const [likedByCurrentUser, setLikedByCurrentUser] = React.useState(false);
  const { isUpVote = false } = props;

  React.useEffect(() => {
    setLikeCount(props.data.likeCount);
    setLikedByCurrentUser(props.data.likedByCurrentUser);
  }, [props.data.likeCount, props.data.likedByCurrentUser]);

  const handleLike = () => {
    if(!likedByCurrentUser){
      setLikeCount(likeCount + 1);
      setLikedByCurrentUser(true);
      props.likeAction();
    }
  };

  const getCounterButtonIcon = (type) => {
    return type == 'like' ?
      isUpVote ?
        <Icon type='up-arrow'
          size={16}
          fill={ likedByCurrentUser ? theme.palette.primary.main : grey[500]}
          stroke={ likedByCurrentUser ? theme.palette.primary.main : grey[500]} /> :
        <Icon type='thumb-up'
          size={16}
          fill={ likedByCurrentUser ? theme.palette.primary.main : grey[500]}
          stroke={ likedByCurrentUser ? theme.palette.primary.main : grey[500]} /> :
      <Icon type='comment'
        size={16}
        fill='none'
        stroke={ grey[500]}/>; 

  };

  return (
    <Box>
      {!props.isLoading &&
        <Box flex sx={{ flexGrow: 1 }}>
          <CounterButton sx={{ p: 0, marginRight: 1, color: likedByCurrentUser ? theme.palette.primary.main :  grey[500] }}
            startIcon={getCounterButtonIcon('like')}
            onClick={handleLike}>
            {likeCount}
          </CounterButton>
          <Box >
            <CounterButton sx={{ p: 0, color: 'text.disabled' }} startIcon={getCounterButtonIcon('comment')} onClick={props.commentAction}>
              {props.data.commentCount}
            </CounterButton>
          </Box>
        </Box>
      }
      {props.isLoading &&
        <Box sx={{ flexGrow: 1 }}>
          <Skeleton width={80} height={35}/>
        </Box>
      }
    </Box>
  );
}